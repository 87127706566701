const artsInfo = [
  {
    id: 0,
    name: "Tranquil Blooms",
    interpretation: ` "Gol va Morgh" is a timeless Persian fable symbolizing the pursuit of freedom and embracing one's true nature. It tells the story of a flower yearning to become a bird but ultimately discovering the beauty and purpose in its own essence. This metaphor inspires us to appreciate our unique qualities and find contentment in our own path.`,
    materials: "Turkish handmade paper, watercolor, gouache",
    size: `Size: 4.53" H x 3.07" W (or 11.5cm H x 7.8cm W)`,
    technique: "Miniature - Flower and Bird (Gol va Morgh)",
    dateOfCreation: "May 2022",
  },
  {
    id: 1,
    name: "Colorburst",
    interpretation: `The "Khataei" method in Persian art is a technique that embraces intentional irregularities and mistakes to create unique and captivating artwork. Derived from the Persian word for "mistake," this method celebrates the beauty that arises from imperfection. Skilled artists employ intricate patterns while deliberately introducing subtle irregularities, adding an organic and enchanting touch to their creations. The concept of “Khataei” aligns with the Persian philosophy of "wabi-sabi," appreciating impermanence and imperfection. This technique not only showcases the artist's skill but also adds depth and character to the artwork, reflecting the rich artistic heritage and aesthetic principles of Persian culture.`,
    materials: "Turkish handmade paper, watercolor, gouache",
    size: `Size: 4.53" H x 3.07" W (or 11.5cm H x 7.8cm W)`,
    technique: "Miniature - Khataei",
    dateOfCreation: "April 2022",
  },
  {
    id: 2,
    name: "Sunburst (Shamseh)",
    interpretation: `The "Shamseh", a traditional Persian decorative motif symbolizing the sun, holds deep cultural and symbolic significance. Representing light, power, and vitality, it embodies the radiant energy and life-giving properties of the sun. The Shamseh's stylized depiction with its emanating rays evokes a sense of warmth, illumination, and divine presence. It serves as a reminder of the sun's role as a universal symbol of life, enlightenment, and spiritual awakening. The Shamseh motif, often found in Persian art and architecture, reflects the appreciation of natural elements and the celebration of the sun's life-affirming qualities. Its presence in Persian culture echoes a reverence for light, growth, and the ever-present source of energy that sustains and nourishes life itself.`,
    materials:
      "24-carat gold, gouache, watercolor, Dutch model-making cardboard",
    size: `25.20" H x 17.32" W (or 64cm H x 44cm W)`,
    technique: "Miniature - Illumination or Gilding (Tazhib)",
    dateOfCreation: "October 2017",
  },
  {
    id: 3,
    name: "Graceful Companions",
    interpretation: `"Gol va Morgh" is a timeless Persian fable symbolizing the pursuit of freedom and embracing one's true nature. It tells the story of a flower yearning to become a bird but ultimately discovering the beauty and purpose in its own essence. This metaphor inspires us to appreciate our unique qualities and find contentment in our own path.`,
    materials: " gouache, watercolor, Dutch model-making cardboard",
    size: `9.06" H x 6.30" W (or 23cm H x 16cm W)`,
    technique: "Miniature - Flower and Bird (Gol va Morgh)",
    dateOfCreation: "August 2011",
  },
  {
    id: 4,
    name: "The Serene Bond",
    interpretation: `[This painting is a meticulous replica of the original artwork by M.B. Aghamiri]."The Serene Bond" is a traditional Persian miniature painting capturing the tranquil connection between a horse and a bird. Their gaze reflects a deep understanding, symbolizing the harmony found in nature's interwoven tapestry.`,
    materials:
      "24-carat gold, gouache, watercolor, Dutch model-making cardboard",
    size: `17.72" H x 12.99" W (or 45cm H x 33cm W)`,
    technique: "Miniature - Flower and Bird (Gol va Morgh)",
    dateOfCreation: "2022/02/02",
  },
  {
    id: 5,
    name: "Shadows and Blooms",
    interpretation: `Within the realm of "Gol va Morgh," a hidden language unfolds. The flowers speak of grace, purity, and the fragility of life, while the birds symbolize freedom, transcendence, and the pursuit of higher realms. This intricate interplay between flora and fauna invites us to explore the subtle messages of nature, reminding us of the interconnectedness of all living beings and the wisdom that can be found in observing the natural world.`,
    materials:
      "24-carat gold, gouache, watercolor, walnut leaf ink, Dutch model-making cardboard",
    size: `14.76" H x 10.83" W (or 37.5cm H x 27.5cm W)`,
    technique:
      "Miniature - Flower and Bird (Gol va Morgh), And Gilding (Tazhib)",
    dateOfCreation: "April 2020",
  },
  {
    id: 6,
    name: "The Myth of Sohrab and Gordafarid",
    interpretation: `[This painting is a meticulous replica of the original artwork by M.B. Aghamiri]. The Battle of Sohrab and Gordafarid" depicts a compelling scene from Persian mythology, taken from the epic poem "Shahnameh" by Ferdowsi. It portrays the gripping encounter between Sohrab, a heroic warrior, and Gordafarid, a formidable and captivating heroine. The artwork captures the essence of their clash, showcasing the bravery, passion, and tragic destiny of these legendary figures. As Sohrab and Gordafarid engage in combat, their conflicting loyalties, undeniable courage, and an underlying sense of destiny intertwine, resulting in a tale of profound sacrifice, valor, and heartrending fate. The painting invites viewers to delve into the rich tapestry of Persian mythology, immersing themselves in the timeless narrative of this epic confrontation.`,
    materials: "walnut leaf ink, Dutch model-making cardboard",
    size: `13.19" H x 8.46" W (or 33.5cm H x 21.5cm W)`,
    technique: "Miniature ",
    dateOfCreation: "September 2014",
  },
  {
    id: 7,
    name: "Celestial Garden",
    interpretation: `In the realm of "Gol va Morgh," flowers and birds come together to tell stories of love and longing. The flowers, vibrant and captivating, symbolize the yearning for affection, while the birds, with their graceful flight, represent the pursuit of connection and companionship. This artistic representation invites us to reflect on the complexities of human emotions, the desire for love, and the search for meaningful connections in our lives.`,
    materials:
      "24-carat gold, gouache, watercolor, Dutch model-making cardboard",
    size: `17.72" H x 12.99" W (or 45cm H x 33cm W)`,
    technique: "Miniature - Flower and Bird (Gol va Morgh)",
    dateOfCreation: "July 2015",
  },
  {
    id: 8,
    name: "Embracing Solitude",
    interpretation: `The cypress holds a significant place in traditional Persian painting, symbolizing enduring beauty, tranquility, and spiritual depth. Its tall and slender form evokes a sense of elegance and resilience. Depicted in landscapes and poetic scenes, the cypress embodies themes of introspection, longing, and the transient nature of life. It serves as a bridge between the earthly and the divine, inviting viewers to reflect on their own journey of self-discovery and find solace in the timeless beauty of nature.`,
    materials: "Turkish handmade paper, watercolor, gouache",
    size: `Size: 4.53" H x 3.07" W (or 11.5cm H x 7.8cm W)`,
    technique: "Miniature",
    dateOfCreation: "March 2022",
  },
];
export default artsInfo;

import "./App.css";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  MeshReflectorMaterial,
  Html,
  Preload,
  ScrollControls,
  useScroll,
} from "@react-three/drei";
import { Perf } from "r3f-perf";
import { useControls } from "leva";
import Items from "./components/Items";
import Aboutme from "./components/Aboutme";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Inf from "./components/Inf";
import { useEffect, useRef } from "react";
import { GiHidden } from "react-icons/gi";

function App() {
  const backgroundColor = "black";
  const lightColor = "blue";
  const floorColor = "blue";
  // const { backgroundColor } = useControls("Canvas", {
  //   backgroundColor: "black",
  // });
  // const { lightColor } = useControls("Lights", {
  //   lightColor: "blue",
  // });
  // const { floorColor } = useControls("Floor", {
  //   floorColor: "blue",
  // });
  const { imgUrl, visibility } = useSelector((state) => state.inf);

  function Rig() {
    useFrame((state) => {
      state.camera.position.lerp(
        {
          x: -state.pointer.x / 3,
          y: -state.pointer.y / 3,
          z: 5,
        },
        0.1
      );
      state.camera.lookAt(0, 0, 0);
    });
  }
  const data = useScroll();

  return (
    <>
      <div className="hero">
        <Canvas camera={{ position: [0, 0, 10], fov: 80 }}>
          <color args={[backgroundColor]} attach={"background"} />

          {/* <ScrollControls
            props={(className = "sss")}
            pages={0}
            enabled={false}
            style={{
              overflowY: `${visibility === "visibel" ? "hidden" : "auto"}`,
            }}
          > */}
          <Html
            fullscreen
            className={`pages ${
              visibility === "visible" ? "locked" : "scrolled"
            }`}
            as="div"
            style={{
              // height: "200vh",
              width: "100vw",
            }}
          >
            <Provider store={store}>
              <Hero />
              <Aboutme />
              <Inf />
              <Contact />
            </Provider>
          </Html>
          {/* </ScrollControls> */}
          {/* <Perf position="top-left" /> */}

          <group position={[0, -0.5, 0]}>
            <Rig from={-Math.PI / 2} to={Math.PI / 2.66} />
            <pointLight
              intensity={0.1}
              color={lightColor}
              position={[0, 4, 4.5]}
              rotation={[0, 0, 0]}
            />
            <Items position={[0, 0, -2]} />
            <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -2, 0]}>
              <planeGeometry args={[50, 50]} />
              <MeshReflectorMaterial
                blur={[300, 100]}
                resolution={2048}
                color={floorColor}
              />
            </mesh>
          </group>

          <Preload all />
        </Canvas>
      </div>
    </>
  );
}

export default App;

import "../App.css";
import "./aboutMe.css";
import { useSelector } from "react-redux";
export default function Aboutme() {
  const { imgUrl, visibility } = useSelector((state) => state.inf);

  return (
    <div
      className={`section `}
      onMouseOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="section-center">
        <div className="aboutMe">
          <h2>About Me</h2>
          <p>
            I am Soudeh Bayat, a highly skilled and passionate Traditional
            Persian Painting Artist. With a deep reverence for Persian art and a
            keen eye for detail, I dedicate myself to creating captivating and
            enchanting works of art.
            <br />
            <br />
            My journey as a painter began with a strong connection to the rich
            artistic traditions of Persia. Drawing inspiration from the
            mesmerizing world of Persian miniatures, I have spent years refining
            my technique and exploring the intricate details that define this
            unique art form.
            <br />
            <br />
            Through my artwork, I strive to capture the essence of Persian
            culture, history, and storytelling. Each brushstroke is a testament
            to my love for the vibrant colors, delicate patterns, and symbolic
            motifs that are characteristic of traditional Persian painting.
            <br />
            <br />
            As a painter, I find solace and fulfillment in the process of
            bringing a blank canvas to life. With meticulous attention to
            detail, I breathe life into each stroke, creating a visual narrative
            that transports viewers to a realm of beauty, grace, and
            tranquility.
            <br />
            <br />
            Drawing on my academic background in tourism management and urban
            affairs, I infuse my paintings with a sense of cultural identity and
            a deep understanding of the significance of art in preserving
            heritage.
            <br />
            <br />I invite you to explore my portfolio and immerse yourself in
            the world of Traditional Persian Painting. Let my artwork take you
            on a journey, where history meets imagination and the strokes of a
            brush tell stories that transcend time. Experience the magic of
            Persian art and allow it to awaken your senses and stir your soul.
          </p>
        </div>
      </div>
    </div>
  );
}

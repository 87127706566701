import { GiCrossMark, GiNextButton, GiPreviousButton } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import { setInfInvisible, setInfUrl } from "../redux/inf";
import artsInfo from "../data/artsInfo";
import { useState, useRef } from "react";

export default function Inf() {
  const { imgUrl, visibility } = useSelector((state) => state.inf);
  const dispatch = useDispatch();
  const [isLoadingLq, setIsLoadingLq] = useState(true);
  const [isLoadingHq, setIsLoadingHq] = useState(true);

  const handleImageLoadLq = () => {
    setIsLoadingLq(false);
  };
  const handleImageErrorLq = () => {
    setIsLoadingLq(true);
  };
  const handleImageLoadHq = () => {
    setIsLoadingHq(false);
  };
  const handleImageErrorHq = () => {
    setIsLoadingHq(true);
  };
  const innerRef = useRef(null);
  const handleTouchMove = (event) => {
    if (!isLoadingHq) {
      const inner = innerRef.current;
      const touch = event.touches[0];
      const rect = inner.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const xAxis = ((touch.clientX - rect.left) / width) * 100;
      const yAxis = ((touch.clientY - rect.top) / height) * 100;
      inner.style.transform = `translate(-${xAxis}%, -${yAxis}%)`;
    }
  };
  const handlePointerMove = (event) => {
    if (!isLoadingHq) {
      const inner = innerRef.current;
      const width = event.target.clientWidth;
      const height = event.target.clientHeight;
      const xAxis = (event.nativeEvent.offsetX / width) * 100;
      const yAxis = (event.nativeEvent.offsetY / height) * 100;
      inner.style.transform = `translate(-${xAxis}%, -${yAxis}%)`;
    }
  };

  return (
    <div
      className={`layout ${visibility}`}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className="inf">
        <button
          className="close-btn"
          onClick={() => {
            setIsLoadingHq(true);
            setIsLoadingLq(true);
            dispatch(setInfInvisible());
          }}
        >
          <GiCrossMark />
        </button>
        <button
          className="next-btn"
          onClick={() => {
            setIsLoadingLq(true);
            setIsLoadingHq(true);
            dispatch(
              setInfUrl(Number(imgUrl) + 1 < 9 ? `${Number(imgUrl) + 1}` : `0`)
            );
          }}
        >
          <GiNextButton />
        </button>
        <button
          className="prev-btn"
          onClick={() => {
            setIsLoadingLq(true);
            setIsLoadingHq(true);
            dispatch(
              setInfUrl(Number(imgUrl) - 1 >= 0 ? `${Number(imgUrl) - 1}` : `8`)
            );
          }}
        >
          <GiPreviousButton />
        </button>

        <div
          className="left"
          onPointerMove={
            //   (e) => {
            //   if (!isLoadingHq) {
            //     const inner = document.querySelector(".inner");
            //     let width = e.target.clientWidth;
            //     let height = e.target.clientHeight;
            //     let xAxis = (e.nativeEvent.offsetX / width) * 100;
            //     let yAxis = (e.nativeEvent.offsetY / height) * 100;
            //     inner.style.transform = `translate(-${xAxis}%, -${yAxis}%)`;
            //   }
            // }
            handlePointerMove
          }
          onTouchMove={handleTouchMove}
        >
          {isLoadingLq && (
            <img
              className="placeholder-overlay"
              src={`images/gifs/spinner.svg`}
            />
          )}
          <img
            src={`images/${imgUrl}.jpg`}
            style={{ display: isLoadingLq ? "none" : "block" }}
            onLoad={handleImageLoadLq}
            onError={handleImageErrorLq}
          />
        </div>
        <div className="right">
          <div className="top">
            <h2>{artsInfo[imgUrl].name}</h2>
            <h3>technique</h3>
            <p>{artsInfo[imgUrl].technique}</p>
            <h3>interpretation</h3>
            <p>{artsInfo[imgUrl].interpretation}</p>
            <h3>Materials</h3>
            <p className="materials">{artsInfo[imgUrl].materials}</p>
            <h3>size</h3>
            <p>{artsInfo[imgUrl].size}</p>
            <h3>date of creation</h3>
            <p>{artsInfo[imgUrl].dateOfCreation}</p>
          </div>
          <div className="bottom">
            <div className="inner" ref={innerRef}>
              {isLoadingHq && (
                <img
                  className="placeholder-overlay"
                  src={`images/gifs/spinner.svg`}
                />
              )}
              <img
                src={`images/hd/${imgUrl}.jpg`}
                alt="high quality paint"
                style={{ display: isLoadingHq ? "none" : "block" }}
                onLoad={handleImageLoadHq}
                onError={handleImageErrorHq}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    rotation: true,
  },

  reducers: {
    startRotation: (state) => {
      state.rotation = true;
    },
    stopRotation: (state) => {
      state.rotation = false;
    },
  },
});

export const { startRotation, stopRotation } = gallerySlice.actions;
export default gallerySlice.reducer;

import React, { useEffect } from "react";
import { MathUtils } from "three";
import { Image } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
import * as THREE from "three";
import { useDispatch } from "react-redux";
import { stopRotation, startRotation } from "../redux/gallery";
import { setInfVisible, setInfUrl } from "../redux/inf";
import "./inf.css";

const damp = MathUtils.damp;

const cameraPosition = new THREE.Vector3(0, 0, 15);
export default function Item({
  url,
  index,
  position,
  rotationY,
  rotationDelta,
}) {
  const dispatch = useDispatch();
  const ref = useRef();
  const [hovered, hover] = useState(false);
  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);
  const over = () => {
    hover(true);
    dispatch(stopRotation());
  };
  const out = () => {
    hover(false);
    dispatch(startRotation());
  };
  const click = () => {
    const element = document.querySelector(".section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    hover(false);
    dispatch(setInfUrl(index));
    dispatch(setInfVisible());
  };

  useFrame((state, delta) => {
    ref.current.lookAt(cameraPosition);
    if (hovered) {
      ref.current.material.grayscale = damp(
        ref.current.material.grayscale,
        0,
        1,
        delta
      );
    } else {
      ref.current.material.zoom = 1;
      ref.current.material.grayscale = damp(
        ref.current.material.grayscale,
        Math.min(1, 1 - Math.cos(rotationDelta + (2 * index * Math.PI) / 8)),
        4,
        delta
      );
    }
  });
  return (
    <>
      <Image
        castShadow
        ref={ref}
        url={url}
        grayscale={1}
        onPointerOver={(e) => {
          e.stopPropagation();
          over();
        }}
        onPointerOut={out}
        onClick={(e) => {
          e.stopPropagation();
          click();
        }}
        scale={hovered ? [2, 3] : [1.25, 1.87]}
        position={position}
        rotation-y={rotationY}
        occlude
        style={{ cursor: "grab" }}
      />
    </>
  );
}
